<template>
  <b-table-simple striped class="table-eyecare">
    <thead>
      <tr>
        <th>Nome</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="surgicalNote, index in surgicalNotes" :key="index"
      >
        <td>{{ surgicalNote.name }}</td>
        <td class="text-right">
          <div class="more-with-menu">
            <MoreVertical class="more-icon" />
            <div class="menu">
              <b-button
                variant="link"
                @click="editSurgicalNote(surgicalNote)"
              >
                Editar
              </b-button>
              <b-button
                variant="link remove mb-0"
                @click="onDeleteClick(surgicalNote)"
              >
                Remover
              </b-button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import { EventBus } from '@/utils/eventBus'
import MoreVertical from '@/assets/icons/more-vertical.svg'

export default {
  name: 'SurgicalNoteTable',
  components: {
    MoreVertical,
  },

  props: {
    surgicalNotes: Array,
    modal: Boolean,
  },

  methods: {
    editSurgicalNote(surgicalNote) {
      EventBus.$emit('editSurgicalNote', surgicalNote)
      this.$bvModal.show('surgical-modal')
    },
    onDeleteClick(surgicalNote) {
      this.$bvModal
        .msgBoxConfirm(
          `Você tem certeza que deseja excluir ${surgicalNote.name}? Não será possível reverter essa ação.`,
          {
            title: 'Excluir anotação cirúrgica',
            size: 'md',
            okTitle: 'Confirmar',
            cancelTitle: 'Cancelar',
            cancelVariant: 'danger',
            centered: true
          }
        )
        .then(confirm => {
          confirm && this.onDelete(surgicalNote)
        })
    },
    async onDelete(surgicalNote) {
      try {
        await this.api.deleteSurgical(surgicalNote.id);
        this.$emit('deletedNote', surgicalNote)
        this.$toast.success('Anotação removido com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
}
</script>
<style lang="scss" >
</style>
