<template>
  <div class="mc-container">
    <div class="header">
      <h4>Nova anotação</h4>
      <b-button
          class="wh-button no-print"
          variant="primary"
          outline
          @click="openNewNote()"
        >
          Nova anotação
        </b-button>
    </div>
    <b-row>
      <v-surgical-table
        :surgicalNotes="anotacoesCirurgicas"
        @deletedNote="deletedNote"
      />
    </b-row>

    <v-surgical-modal 
      :surgicalNote="surgicalNote" 
      @noteCreated="noteCreated"
      @noteUpdated="noteUpdated"
    />
  </div>
</template>
  
  <script>
  import SurgicalNoteModal from '@/components/SurgicalNote/SurgicalNoteModal.vue'
  import SurgicalNoteTable from '@/components/SurgicalNote/SurgicalNoteTable.vue'
  import { EventBus } from '@/utils/eventBus'

  export default {
    name: 'SurgicalNote',
    components: {
      'v-surgical-modal': SurgicalNoteModal,
      'v-surgical-table': SurgicalNoteTable,
    },
    async mounted() {
      await this.getSurgicalNotes()
      EventBus.$on('editSurgicalNote', data => {
      this.surgicalNote = data
    })
    EventBus.$on('reloadSurgicalNotesAdmin', async () => await this.getSurgicalNotes())
    },
    data() {
      return {
        search: null,
        surgicalNote: null,
        anotacoesCirurgicas: [],
        anotacoesOptions: [],
        currentPage: null,
        perPage: 3,
        totalItems: null
      }
    },
    methods: {
      async getSurgicalNotes() {
        try {
          const response = await this.api.getSurgicalNotes(
          this.professional_id, 
          this.name,
          this.template,)
          this.anotacoesCirurgicas = response.data
          this.anotacoesOptions = response.data.map(note => note.name)
        } catch (error) {
          this.$toast.error(error.message)
        }
      },
    getDefaultForm() {     
        return {
          name: null,
          template: null,
        }
      },
    openNewNote() {
      this.surgicalNote = this.getDefaultForm()
      this.$bvModal.show('surgical-modal')
    },

    async deleteModal(surgicalNote) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Você tem certeza que deseja remover o comentário personalizado 
          <span>${surgicalNote.name}</span>?
          <p>Não será possível reverter essa ação.</p>
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteTemplate(surgicalNote)
        }
      })
    },

    deletedNote(note) {
      const index = this.anotacoesCirurgicas.findIndex(n => note.id === n.id) 
      this.anotacoesCirurgicas.splice(index, 1)
    },
    noteCreated(note) {
      this.anotacoesCirurgicas.push(note)
    },
    noteUpdated(note) {
      const noteFound = this.anotacoesCirurgicas.find(n => note.id === n.id) 
      noteFound.name = note.name
      noteFound.template = note.template
    },
    
    },
    watch: {

    }
  }
  </script>
  
  <style lang="scss" scoped>
  .mc-container {
    min-height: 100vh;
    padding: 40px 20px;
  
    .header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
  
      h4 {
        font-weight: 700;
      }
    }
  
    .search {
      border-radius: 8px !important;
      margin-bottom: 20px;
    }
  }
  </style>
  